<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-28 15:47:09
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-11-28 15:48:29
-->
<template>
  <router-view />
</template>

<script>
export default {};
</script>

<style>
</style>